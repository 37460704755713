<template>
  <div>
    <CRow>
      <CCol col="6" sm="6" md="4" lg="3" xl class="pl-10 mb-3 mb-xl-0">
        <CButton
          style="max-width: 160px; margin-left: 30px; height: 120px;"
          class="dash-btn"
          to="/files/new"
          size="lg"
          color="primary"
        >
          <h3>New Case File</h3>
        </CButton>
      </CCol>

      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton
          class="dash-btn"
          size="lg"
          to="/bringups/overdue"
          color="success"
        >
          <h3>BUs: Overdue</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["overdue"]
          }}</CBadge>
        </CButton>
      </CCol>
    </CRow>
    <hr />

    <CRow>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton class="dash-btn" size="lg" to="/files/active" color="dark">
          <h3>Active Files</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["active"]
          }}</CBadge>
        </CButton>
      </CCol>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton class="dash-btn" size="lg" to="/files/dormant" color="dark"
          ><h3>Dormant Files</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["dormant"]
          }}</CBadge>
        </CButton>
      </CCol>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton
          class="dash-btn"
          size="lg"
          to="/files/sendtoclient"
          color="dark"
          ><h3>Send To Client</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["sendtoclient"]
          }}</CBadge>
        </CButton>
      </CCol>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton class="dash-btn" size="lg" to="/files/closed" color="dark"
          ><h3>Closed Files</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["closed"]
          }}</CBadge>
        </CButton>
      </CCol>
    </CRow>
    <hr />

    <CRow>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton to="/bringups/today" class="dash-btn" size="lg" color="success"
          ><h3>BUs: Today</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["today"]
          }}</CBadge>
        </CButton>
      </CCol>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton to="/bringups/week" class="dash-btn" size="lg" color="success"
          ><h3>BUs: This Week</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["week"]
          }}</CBadge>
        </CButton>
      </CCol>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton to="/bringups/month" class="dash-btn" size="lg" color="success"
          ><h3>BUs: This Month</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["month"]
          }}</CBadge>
        </CButton>
      </CCol>
      <CCol col="6" sm="6" md="4" lg="3" class="mb-3 mb-xl-0 text-center">
        <CButton
          to="/bringups/quarter"
          class="dash-btn"
          size="lg"
          color="success"
          ><h3>BUs: This Quarter</h3>
          <LoadingGifSmall v-if="loading" />
          <CBadge v-else shape="pill" color="warning">{{
            counts["qtr"]
          }}</CBadge>
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
// import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import LoadingGifSmall from "./components/LoadingGifSmall";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    // WidgetsDropdown,
    WidgetsBrand,
    LoadingGifSmall,
  },
  data() {
    return {
      counts: [],
      loading: false,
    };
  },
  beforeCreate() {
    // this.check_login_time();
  },
  mounted() {
    this.check_login_time();
    this.fetch_data_counts();
  },
  methods: {
    check_login_time() {
      const axios = require("axios");
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      const login_time = parseInt(localStorage.getItem("login_time"));

      const time_now = Math.floor(Date.now() / 1000);
      const days3ago = 3 * 24 * 60 * 60;

      //logout if login_time is not set or login_time > 3 days ago
      if (!login_time || time_now - login_time > days3ago) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("login_time");

        axios
          .post("/logout", user)
          .then((res) => {
            console.log(res);
            // localStorage.removeItem("user");
            // localStorage.removeItem("token");
            // this.$router.push("/a/login");
            window.location.replace("/a/login");
            // window.location.
          })
          .catch((err) => {
            console.log(err);
            // this.$router.push("/a/login");
            window.location.replace("/a/login");

            // Swal.fire("Oops...", err.message, "error");
          });
      }
    },
    fetch_data_counts() {
      const axios = require("axios");

      var vm = this;
      vm.loading = true;
      console.log("loading: " + vm.loading);

      axios
        .get("/cases/counts")
        .then(function(res) {
          // handle success
          vm.counts = res.data;
          console.log(vm.counts);
          vm.loading = false;
          console.log("loading: " + vm.loading);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          vm.loading = false;
          console.log("loading: " + vm.loading);
        });
    },
  },
  created() {
    // var user = JSON.parse(localStorage.getItem('user'))
    // if(!user) {
    //   this.$router.push('/a/login')
    // }
  },
};
</script>

<style scoped>
.dash-btn {
  height: 100%;
  padding-top: 1.5rem;
  max-width: 220px;
}
</style>
